html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  min-height: 100%;
  display: flex;
  #root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
}

.DraftEditor-root {
  min-height: 4rem;
  border-width: 1px;
  border-color: rgb(209, 213, 219);
  border-radius: 30;
  background-color: rgb(249, 250, 251);
}

.bg-chat {
  background-color: #f6f6f7;
}

.bg-chat-dark {
  background-color: #090908;
}

.bg-dark {
  background-color: #2f2f34;
}

.bouncing-loader {
  display: inline-block;
  clip-path: inset(0 0.7ch 0 0);
  animation: l 2s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}

.min-footer {
  min-height: 6rem;
}

.h-90 {
  height: 100%;
}

.h-max-chat {
  max-height: 75%;
}

.h-with-header {
  max-height: 75%;
}

.h-without-header {
  max-height: 85%;
}

.min-chat-input {
  min-height: 2.5rem;
}

.dot-flashing {
  position: relative;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.25rem;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -0.75rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.25rem;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 0.75rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.25rem;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

.h-90-percent {
  height: 90%;
}

.collapsed {
  animation: fadeOut 10s linear 0s infinite;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.chat-scroll > :first-child {
  margin-top: auto;
}

.bottom-chat-input {
  bottom: 50%;
}

.bottom-chat {
  bottom: 40%;
}
